<template>
    <section :id="cmsBlock.anchorTag" :class="cmsBlock.textBlockWithIconsStyle">
        <div v-html="md(localize(cmsBlock.text))" class="cms-content"></div>
        <div class="icon-links">
            <template v-for="(iconLink, index) in cmsBlock.iconLinks">
                <a v-if="!isInternalLink(localize(iconLink.iconLink))" :key="iconLink.id"
                    :href="localize(iconLink.iconLink)">
                    <picture>
                        <template v-for="[format, set] of Object.entries(srcsets[index])">
                            <source v-if="format !== originalFormats[index]" :srcset="set" sizes="500px"
                                :type="'image/' + format.slice(1)">
                        </template>
                        <img :srcset="srcsets[index][originalFormats[index]]"
                            :type="'image/' + originalFormats[index].slice(1)" sizes="500px"
                            :alt="iconLink.icon.data.attributes.alternativeText">
                    </picture>
                </a>
                <router-link v-if="isInternalLink(localize(iconLink.iconLink))" :key="iconLink.id"
                    :href="localize(iconLink.iconLink)">
                    <picture>
                        <template v-for="[format, set] of Object.entries(srcsets[index])">
                            <source v-if="format !== originalFormats[index]" :srcset="set" sizes="500px"
                                :type="'image/' + format.slice(1)">
                        </template>
                        <img :srcset="srcsets[index][originalFormats[index]]"
                            :type="'image/' + originalFormats[index].slice(1)" sizes="500px"
                            :alt="iconLink.icon.data.attributes.alternativeText">
                    </picture>
                </router-link>
            </template>

        </div>
    </section>
</template>

<script>
export default {
    name: 'TextBlockWithIcons',

    props: [
        'cmsBlock'
    ],

    computed: {
        srcsets() {
            let srcsets = []
            for (const iconLink of this.cmsBlock.iconLinks) {
                srcsets.push(this.getImageSrcsets(iconLink.icon.data.attributes))
            }
            return srcsets
        },

        hasWebp() {
            let hasWebps = []
            for (const srcset of this.srcsets) {
                hasWebps.push(srcset['.webp'] ? true : false)
            }
            return hasWebps
        },

        originalFormats() {
            let originalFormats = []
            for (const srcset of this.srcsets) {
                for (const [format, _] of Object.entries(srcset)) {
                    if (format !== '.webp') {
                        originalFormats.push(format)
                    }
                }
            }

            return originalFormats
        },
    }
}
</script>

<style lang="scss" scoped>
section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cms-content {
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 24px;
        padding-bottom: 1px;

        @include breakpoint('max-width') {
            padding: 0 getSpacing('section-left-right', 'desktop');
        }

        @include breakpoint('tablet') {
            padding: 0 getSpacing('section-left-right', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: 0 getSpacing('section-left-right', 'mobile');
        }
    }

    .icon-links {
        a {
            img {
                max-height: 250px; //new in 4.0
                padding: 10px;
            }
        }
    }
}</style>
